const setFontMixin = {
	methods : {
		setFont() {
			const x = document.createElement('link');
			x.rel   = 'preconnect';
			x.href  = 'https://fonts.gstatic.com';
			document.head.appendChild(x);

			const s = document.createElement('link');
			s.rel   = 'stylesheet';
			s.href  = this.config.font_type_url !== null ? this.config.font_type_url : 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap';
			document.head.appendChild(s);
		},
	}
};

export default setFontMixin;
