<template>
	<div class="row disclaimer">
		<div class="col-12">
			<p>
				Esta página é parte integrante do Rotauto.com. Toda a informação pessoal fornecida ou recolhida nos
				nossos Sites
				é controlada pela YNEXUS Digital Tribe, Lda, com sede em Rua Camilo Korrodi, Bloco 5, 2º E-15, 2400-111
				Leiria,
				sendo esta responsável pelo tratamento dos dados pessoais dos seus Utilizadores. Qualquer contacto sobre
				este
				assunto deverá ser remetido para privacidade@rotauto.com. Dada a natureza dos Produtos e Serviços do
				Rotauto, e
				para que seja possível cumprirmos as nossas obrigações e entregar os resultados pedidos e contratados
				pelos
				nossos Utilizadores, será necessário partilhar os dados dos Utilizadores com empresas e/ou fornecedores
				nos
				quais os Utilizadores tenham explicitamente demonstrado interesse nos seus Produtos e Serviços ou
				vontade de
				contactar ou ser contactados por estes no decorrer da utilização do Site Rotauto ou da recepção de
				comunicações
				eletrónicas do Rotauto ou campanhas por nós geridas. Recomendamos que leia a nossa Política de
				privacidade para
				estar informado sobre o que fazemos com a sua informação pessoal e como a mantemos em segurança.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name : 'Disclaimer'
};
</script>
