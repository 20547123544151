const setWhatsappPosMixin = {
  methods: {
    setPos() {
      if (
        (this.whatsappActive &&
          this.config.sticky_button === 1 &&
          this.config.sticky_button_text === null &&
          this.config.sticky_button_call_number !== null) ||
        (this.whatsappActive &&
          this.config.sticky_button === 1 &&
          this.config.sticky_button_text !== null &&
          this.config.sticky_button_call_number === null)
      ) {
        setTimeout(() => {
          document
            .getElementById("whatsapp-float")
            .setAttribute("style", "bottom:10px !important;");
        }, 0);
      } else if (
        this.whatsappActive &&
        this.config.sticky_button === 1 &&
        this.config.sticky_button_text !== null &&
        this.config.sticky_button_call_number !== null
      ) {
        setTimeout(() => {
          document
            .getElementById("whatsapp-float")
            .setAttribute("style", "bottom:90px !important;");
        }, 0);
      }
    },

    handleScroll(event) {
      if (this.whatsappActive && this.config.sticky_button !== 1) {
        const viewportHeight = window.innerHeight;
        const element = document.getElementById("footer");
        if (element !== null && element !== undefined) {
          const distance = element.getBoundingClientRect().top;
          if (Math.floor(distance) <= viewportHeight) {
            document
              .getElementById("whatsapp-float")
              .setAttribute("style", "bottom:70px !important;");
          } else {
            document
              .getElementById("whatsapp-float")
              .setAttribute("style", "bottom:25px !important;");
          }
        }
      }
    },
  },
};

export default setWhatsappPosMixin;
