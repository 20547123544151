<template>
	<div class="whatsapp-float">
		<a id="whats" :href="url" target="_blank" @click="trigger" rel="noopener">
			<img :src="require(`@/assets/images/WhatsApp.svg`)" alt="whatsapp" width="70px">
		</a>
		<b-tooltip target="whats" title="Fale connosco!" placement="left" variant="success"></b-tooltip>
	</div>
</template>

<script>
import * as moment from 'moment';

export default {
	name  : 'WhatsappFloat',
	props : {
		config : {
			type     : Object,
			required : true
		},
	},
	data() {
		return {
			url         : String,
			isStockPage : this.config.template === 'catalog_v1' || this.config.template === 'catalog_v2',
		};
	},
	created() {

		if (this.isStockPage) {
			setTimeout(() => {
				let data    = this.$store.state.listingData.data;
				let options = {
					make  : data.CarNexus.car_makes.MakeName === 'Skoda' ? 'ŠKODA' : data.CarNexus.car_makes.MakeName,
					model : data.CarNexus.ModelName,
					price : data.SalePrice,
					year  : moment(data.CarMainData.RegistrationDate, 'YYYY-MM-DD hh:mm:ss').format('YYYY'),
				};
				let contact = this.config.whatsapp_phone !== null ? this.config.whatsapp_phone : data.Address.seller_stand.whatsapp_contact;
				let message = this.config.whatsapp_message !== null ? this.setMessageVariables(this.config.whatsapp_message, options) : this.setText(options);
				this.url    = `https://wa.me/${contact}?text=${message}`;
			}, 1000);
		} else {
			this.url = `https://wa.me/${this.config.whatsapp_phone}?text=${this.config.whatsapp_message}`;
		}
	},
	methods : {
		setMessageVariables(message, options) {
			let msg = message;
			if (message.includes('{make}')) {
				msg = msg.replace('{make}', options.make);
			}
			if (message.includes('{model}')) {
				msg = msg.replace('{model}', options.model);
			}
			if (message.includes('{price}')) {
				msg = msg.replace('{price}', `${options.price}€`);
			}
			if (message.includes('{year}')) {
				msg = msg.replace('{year}', options.year);
			}
			return msg;
		},
		setText(options) {
			let encodedURL = encodeURI(window.location.href);
			let text       = encodeURI(`Estou interessado no ${options.make} ${options.model} de ${options.year} por ${options.price}€. Podem dar-me mais informações?`);
			return `${text}%0A%0AURL%3A${encodedURL}`;
		},
		trigger() {
			this.$gtm.trackEvent({
				event          : 'Whatsapp_opened', // Event type [default = 'interaction'] (Optional)
				category       : 'Whatsapp_opened',
				action         : 'Whatsapp_opened',
				label          : 'Whatsapp_opened',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'Contact' });
		}
	}
};
</script>
